// 5. Site Footer
.snFooterContainer {
  width: 100vw;
  // max-width: $maxWidth;
  margin: 0 auto;
  background-color: $link-color;
}
.page-manager-visible {
  .logged_in {
    .snFooterContainer {
      width: calc(100vw - 60px);
      // max-width: $maxWidth;
      max-width: 1220px;
    }
  }
}



#siteFooter {
  @extend %clearfix;
  display: block;
  margin: auto;
  padding: 10px 20px;
  position: relative;
  vertical-align: bottom;
  width: 100%;
  font-size: .8em;
  clear: both;

  > ul {
    padding: 0;
    @include displayCenter($maxWidth);

    li {
      display: inline-block;
      padding: 0 .2em;
      min-height: 20px;
      color: $white;

      a {
        display: inline-block;
        color: $white;
      }

      &:hover {
        display: inline-block;
      }
    }
  }
}

#siteFooter li#poweredByNGIN {
  a {
    display: block;
    width: 120px;
    height: 20px;
    position: absolute;
    right: 0;

    &:after {
      content: url($asset-path-for+'/logo_images/logo.svg');
      display: block;
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
    }

    img {
      display: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  #siteFooter {
    li {
      min-height: 0;
    }
  }

  #siteFooter li#poweredByNGIN {
    padding: 10px 0;
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  #siteFooter {
    li {
      min-height: 0;
    }
  }

  #siteFooter li#poweredByNGIN {
    padding: 10px 0;
  }
}
