//Overflow Fix
body, html {
  overflow-x: hidden;
}

//Hide SubNav on Home
body.home {
  #topNavPlaceholder {
    height: 175px;
  }
  #topNav {
    .theme-sub-nav {
      display: none !important;
    }
  }
}

//Last of Main Nav Item
.theme-main-nav {
  .theme-nav {
    > .theme-nav-item {
      &:last-of-type {
        background-color: $accent-color;

      }
    }
  }
}
#topNav .theme-main-nav .theme-nav > .theme-nav-item:last-of-type > .theme-nav-link:not(x):hover {
  background-color: $accent-color-hover;
}

//Title Name Header
.nameHeader {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    h6 {
      font-size: 16px;
      font-weight: 500;
    }
    h1 {
      font-size: 32px;
      font-weight: 700;
      line-height: 28px;
    }
}

//Affiliate Logos
.affiliates {
    display: flex;
    float: right;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-100%);
    width: 465px;
    justify-content: space-around;
    left: 100%;
    height: 85px;
    overflow: hidden;
    a {
      height: 85px;
      width: 85px;
      img {
        max-width: 85px;
        max-height: 85px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity .3s linear;
        &:hover {
          opacity: .8;
          transition: opacity .3s linear;
        }
      }
    }

}

//Custom Media Slider
.user_mode {
  .full-width {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      position: relative;
      margin-top: -30px;
      .hero-slider {
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        .linkContainer {
            left: 50%;
            transform: translateX(-50%) translateY(60%);
            position: relative;
            width: 175px;
            height: 40px;
        }
        .linkElement {
          max-width: 175px;
        }
      }
  }
}
.logged_in{
  .full-width {
      width: calc(100vw - 60px);
      left: 50%;
      transform: translateX(-50%);
      position: relative;
      margin-top: -30px;
      .hero-slider {
        width: calc(100vw - 60px);
        left: 50%;
        transform: translateX(-50%);
        position: relative;
      }
  }
}
.edit_mode{
  .full-width {
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      position: relative;
      margin-top: 0px;
      .hero-slider {
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        position: relative;
      }
  }
}
.hero-slider {
  .mediaSlider {
    margin: 0px;
    .sn-media-slider {
      max-height: 400px;
      .flexslider {
        max-height: 400px;
        .slider-pagination {
          bottom: 70px;
          .paging-item {
            height: 10px;
            width: 10px;
            background: $link-color;
            margin: 3px;
          }
          .flex-active {
            background: $accent-color;
          }
        }
        .slide-title {
          font-size: 36px;
          font-family: $fontOne;
          text-shadow: none;
          margin: 0px;
          font-weight: 500;
        }
        .slide-description {
          text-shadow: none;
          font-size:16px;
          font-family: $fontTwo;
          font-weight: 400;
          margin: 2% 18%;
        }
        .slides {
          max-height: 400px;
          .slide {
            max-height: 400px;
            .slide-overlay {
              padding: 2% 15% 6% 15%;
              background: rgba(0,0,0,0.6);
            }
            .media-wrapper {
              max-height: 400px;
              img {
                display: block;
                max-height: 400px;
                width: auto !important;
                left: 50% !important;
                transform: translateX(-50%) !important;
              }
            }
          }
        }
      }
    }
  }
}
.hero-spacer {
    height: 40px;
    background: white;
    // box-shadow: 0px -6px 10px 0px rgba(0,0,0,0.3);
}

.user_mode {
  .hero-spacer {
      max-width: 1220px;
      left: 50%;
      transform: translateX(-50%);
      position: relative;
      margin-top: -40px;
  }
}

//Search
.searchIconButton {
    height: 50px;
    display: block;
    position: relative;
    width: 70px;
    float: left;
    cursor: pointer;
    &:after {
      content: "\f002";
      font-family: "fontawesome";
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      }
}
.theme-search-bar {
  div#PageSearchBoxTopNav {
    visibility: hidden;
  }
}
.topNav {
  div#PageSearchBoxTopNav {
      background: $accent-color;
      color: #003c71;
      font-size: 12px;
      float: right;
      border-radius: 0px 0px 0px 10px;
      padding: 5px 20px;
      display: none;
      width: 250px;
      .theme-search-input {
        float: left;
        padding: 3.5px;
        margin: 1.5px;
        border: 1px solid white;
      }
      .theme-search-submit {
      float: left;
      background: $accent-color;
      color: white;
      font-size: 10px;
      padding: 2px 4px;
      margin: 2px;
      border: 1px solid white;
    }
  }
}

//Home CTA
#callToAction-66436, #callToAction-66437 {
  margin: 0px;
  margin-bottom: 27px;
}

//Custom CTA link
.cta-link {
  .linkElement{
    h4{
      font-family: $fontOne;
      font-size: rem(20);
      font-weight: 700;
      border-left: 5px solid $accent-color;
      a{
        background-color: $link-color;
        display: block;
        color: #fff;
        // padding: 10px 20px 10px 10px;
        padding: 0;
        margin-bottom: 0px;
        line-height: 56px;
        text-transform: uppercase;
        text-decoration: none;
        transition: background-color .2s;
        max-height: 56px;
        min-height: 55px;
        padding-right: 15px;
        text-align: center;
        font-weight: 500 !important;

        &:hover {
          background-color: $hover-color;
        }

      }
    }
  }
  .goToLink,
  .emailLink{
    &:before{
      display:none;
    }
    &:after{
      color: #ffffff;
      content: "\f101";
      font-family: FontAwesome;
      font-size: 16px;
      font-weight: 400;
      display: inline-block;
      line-height: 55px;
      margin: 0 0 0 6px;
      padding: 0;
      transition: color .2s;
      right: 0;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      background: $hover-color;
      width: 55px;
    }
    &:hover:after {
      color: #fff;
    }
  }
}
.home {
  .cta-link {
    .pageElement {
      margin: 15px 0px 0px 0px;
    }
  }
}

//Home Site container
.home {
  #siteContainer {
    padding: 15px 15px;
    margin-top: 0px;
    margin-bottom: 40px;
  }
}


@media only screen and (min-width: 1080px) {
  .user_mode {
    .custom-tabs {
      .tabbedElement {
        padding-top: 25px;
        .contentTabs{
          background: white;
          width: auto;
          left: 50%;
          transform: translateX(-50%);
          position: absolute;
          margin: 0;
          top: 15px;
          transition: all .2s linear;
          z-index: 2;
          li {
            border: 1px solid $link-color;
            margin-left: 7.5px;
            margin-right: 7.5px;

            &.selected {
              border: 1px solid $accent-color;
                span {
                  a {
                    color: $accent-color !important;
                    transition: color .2s linear;

                  }
                }
              &:hover {
                background: $accent-color;
                transition: background .2s linear;

                span {
                  a {
                    color: white !important;
                    transition: color .2s linear;

                  }
                }

              }
            }
            &:hover {
              background: $link-color;
              transition: background .2s linear;

              span {
                a {
                  color: white !important;
                  transition: color .2s linear;

                }
              }

            }
            span {
              a {
                font-size: 16px;
                text-transform: capitalize;
                font-weight: 500;
              }
            }
          }
        }
        .tabContainer {
          padding-top: 30px;
          border-top: 1px solid #f0f2f7;

        }
      }
    }
  }
}
.tabbedElement {
  // padding-top: 25px;
  .contentTabs{
    // background: white;
    // width: auto;
    // left: 50%;
    // transform: translateX(-50%);
    // position: absolute;
    // margin: 0;
    // top: 15px;
    // transition: all .2s linear;
    // z-index: 2;
    li {
      border: 1px solid $link-color;
      margin-left: 7.5px;
      margin-right: 7.5px;

      &.selected {
        border: 1px solid $link-color;
      }
      &:hover {
        background: $link-color;
        transition: background .2s linear;

        span {
          a {
            color: white !important;
            transition: color .2s linear;

          }
        }

      }
      span {
        a {
          font-size: 16px;
          text-transform: capitalize;
          font-weight: 500;
        }
      }
    }
  }
  // .tabContainer {
  //   padding-top: 30px;
  //   border-top: 1px solid #f0f2f7;
  //
  // }
}

//FOOTER STYLES
.logged_in {
  #customFooter {
    max-width: calc(100vw - 60px;);
  }
}
#customFooter {
  display: flex;
  max-width: $maxWidth;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  .footerLeft, .footerRight {
    width: calc(100vw / 2);
    margin-top: 30px;
    margin-bottom: 30px;
    .footer-left {
        display: flex;
      a {
        color: #fff;
        font-weight: 400;
      }
    }
     .footer-right {
       display: flex;
      a {
        color: #fff;
        font-weight: 400;
      }
    }
    .column {
      width: calc(1200px / 5);
    }
    .textBlockElement {
      text-align: center;
      font-family: $fontOne;
      color: $white;
      h3 {
        font-size: 16px;
        color: $white;
      }
      p {
        font-size: 12px;
      }
    }
    .linkElement {
      max-width: 150px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
#customFooterTop {
  background: $hover-color;
  overflow: hidden;
  .footerTop {
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    max-width: 1200px;
    .footer-top {
      .column {
        width: calc(100vw / 2);
        max-width: 600px;
        width: 50%;
        .textBlockElement {
          float: left;
          height: 115px;
          max-height: 115px;
          margin: 0px;
          .leftTextImage {
            width: 90px !important;
            min-height: 115px !important;
            padding: 0;
            margin: 0;
            img {
              width: 90px !important;
              top: 50%;
              transform: translateY(-50%);
              position: absolute;
            }
          }
          h1 {
            color: #fff;
            font-family: $fontOne;
            font-weight: 700;
            font-size: 30px;
            line-height: 1;
          }
          h6 {
            color: #fff;
            font-family: $fontOne;
            font-weight: 400;
            font-size: 15px;
            line-height: 1;
          }
          .text {
            height: auto;
            width: auto;
            padding: 0;
            margin: 0;
            display: inline;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 105px;
          }
        }
        .heroPhotoElement {
          width: 130px;
          float: right;
          right: 10px;
          height: 115px;
          margin: 0;
          a {
            position: absolute;
            top: 57%;
            transform: translateY(-50%);
          }
          .cutline {
            background: transparent;
            color: #fff;
            font-size: 10px;
            font-family: $fontOne;
            text-align: center;
            font-style: normal;
            top: 20px;
            position: absolute;
            border: none;
          }
        }
      }
    }
  }
}

.edit_mode #customFooter, .edit_mode #customFooterTop {
    .column {
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: calc(100% - 35px);
            height: calc(100% - 60px);
            color: white;
            text-align: center;
            transition: $transition-standard;
            padding: 30px 17px;
        }
        &:hover:before {
            content: 'Please visit the Assets page to edit your footer information.';
            background: rgba(255,0,0,.75);
            z-index: 1;
        }
    }
}


// @media only screen and (min-width: 1200px) {
//   #customFooter {
//     .footerRight,.footerLeft {
//       .column {width: 100%;}
//     }
//   }
// }
@media only screen and (max-width: 1100px) {
  .affiliates {
    top: 25%;
    height: 65px;
    width: 400px;
    a {
      height: 65px;
      width: 65px;
      img {
        width: 100% !important;
        max-width: 65px;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
 .hero-spacer {
   display: none;
 }
 #callToAction-66436, #callToAction-66437 {
   margin: 15px 0px;
  //  margin-top: 30px;
 }

}


//responsive footer
@media only screen and (max-width: 1024px) {
  #customFooter {
    display: block;
    .footerLeft, .footerRight {
      width: 100%;
      margin: 0;
      .footer-left {
        display: block;
        padding-right: 0px;
      }
       .footer-right {
         display: block;
         padding-left: 0px;
      }
      .column {
        width: 100%;
      }
      .textBlockElement {
        h3 {
          font-size: 16px;
          font-family: $fontTwo;
          color: #fff;
          text-align: center !important;
        }
      }
    }
  }
  #customFooterTop {
    .footer-top {
      .column {
        padding: 0px 30px;
        .textBlockElement {
          .leftTextImage {
            float: right;
            img {

            }
          }
          .text {
            display: none !important;
          }
        }
        .heroPhotoElement {
          float: left !important;
          a {

          }
          .cutline {
          }
        }
      }
    }
  }
  #siteFooter {
    text-align: center;
    li#poweredByNGIN {
      a {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  #customFooterTop {
    .footer-top {
      .column {
        width: auto !important;
        max-width: 767px !important;
        padding: 0px 30px;
        .textBlockElement {
          .leftTextImage {
            float: none !important;
            left: 50%;
            transform: translateX(-50%);
            position: relative;
            img {

            }
          }
          .text {
            display: none !important;
          }
        }
        .heroPhotoElement {
          float: none !important;
          left: 50%;
          position: relative;
          transform: translateX(-50%);
          clear: both !important;
          a {

          }
          .cutline {
          }
        }
      }
    }
  }
}


.has-mobile-nav {
  .tabbedElement ul.contentTabs li {
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    margin: 2px;
  }
  .cta-link .linkElement h4 {
    font-size: 14px !important;
  }
}
//responsive home
@media only screen and (max-width: 767px) {
  .hero-slider {
    .slider-pagination {
      display: none;
    }
    .slide-overlay {
      padding: 0;
    }
  }
  .home #siteContainer {
    margin: 0 !important;
  }

}
@media only screen and (max-width: 600px) {
  .hero-slider .mediaSlider .sn-media-slider .flexslider .slides .slide .slide-overlay {
    padding: 0px;
  }
 .linkContainer {
   display: none;
 }
}

@media only screen and (max-width: 600px) {
  .hero-slider .mediaSlider .sn-media-slider .flexslider .slide-title {
    font-size: 18px;
  }
  .hero-slider .mediaSlider .sn-media-slider .flexslider .slide-description {
    font-size: 10px;
  }
}

@media only screen and (max-width: 375px) {
  .extendedOptions {
    display: none;
  }
  .newsAggregatorElement {
      .item {
        & > a {
          display: none;
        }
      }
  }
  .newsSlideShow-thumbnails {
    bottom: 30px;
  }
  .newsSlideShow {
    margin: 0;
    .newsSlideShow-container {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 340px) {
  .eventAggregatorElement {
    .dateImage {
      width: 100%;
    }
  }
}

.has-mobile-nav body:before {
  background: $link-color;
}

.has-mobile-nav.slideNavCollapsed.collapsed-mobile-nav #sn-site-logo {
  display: block;
  height: 44px;
  left: 50%;
  transform: translateX(-50%);
  transition: display 2s linear;
}

.has-mobile-nav.slideNavCollapsed.collapsed-mobile-nav.slide-nav-open #sn-site-logo {
  display: none;

}

 .has-mobile-nav .home .newsAggregatorElement {
  margin-top: 60px !important;
}

.has-mobile-nav .logged_in #accentNav {
  background: $link-color !important;
}


  .has-mobile-nav .theme-search-wrapper {
    display: block !important;
    max-width: 100vw !important;
    background: $link-color !important;
}

html.has-mobile-nav .home #topNavPlaceholder {
    display: block;
    height: 82px;
}

html.has-mobile-nav #topNavPlaceholder {
  display: block;
  height: 41px;
}

.has-mobile-nav .affiliates {

    background: #d22630;
    width: calc(100vw + 1px);
    top: calc(100% - 27px);
    height: 55px;
    border-top: 2px solid white;
    a {
      height: 45px;
      width: 45px;
      img {
        max-width: 45px;
        max-height: 45px;
      }
    }
}

.has-mobile-nav.slideNavCollapsed.collapsed-mobile-nav #sn-site-logo {
    height: 65px;
    top: 3px;
    left: 44px;
    transform: none;
    width: 65px;
}

.has-mobile-nav .nameHeader h1, .nameHeader h6 {color: white;}

.has-mobile-nav .nameHeader h6 {
    font-size: 2vw;
    left: 100px;
    position: relative;
    top: -23px;
    /* line-height: 1; */
}

.has-mobile-nav .nameHeader h1 {
    font-size: 3vw;
    left: 100px;
    position: relative;
    top: -31px;
    /* line-height: 1; */
}

.has-mobile-nav .theme-search-bar {
    left: 0px !important;
    max-width: 100vw !important;
}

.nameHeader {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    display: block;
    height: initial;
    /* width: 100vw; */
}


@media only screen and (max-width: 767px) {
  .has-mobile-nav .nameHeader h6 {
    font-size: 3vw;
    left: 100px;
    position: relative;
    top: -27px;
    line-height: 1;
}

.has-mobile-nav .nameHeader h1 {
    font-size: 4vw;
    left: 100px;
    position: relative;
    top: -25px;
    line-height: 1;
}
}

.has-mobile-nav #siteContainer {
  margin-bottom: 0px;
}

.has-main-nav {
  .nameHeader {
    h6 {
      color: $link-color !important;
    }
    h1 {
    color: $link-color !important;
    }
  }
}

.theme-nav-style-slide .theme-nav-title-text {
  border-bottom: 2px solid #fff;
}

.theme-nav-forward.page_node_3067332 {
  display: none;
}

.theme-nav-style-slide .theme-nav-forward {
  width: 46px;
}

.theme-nav-style-slide .theme-nav-back {
  border-bottom: 2px solid white;
}

.sn-media-slider {
  .slider-pagination {
    .paging-item {
      background: $link-color;

    }
    .paging-item.flex-active {
      background: $accent-color;
    }
  }
}

.cta-red .sn-call-to-action .sn-call-to-action-title:after {
  border: 5px solid $accent-color;
}




@media only screen and (max-width: 1024px) {
  .desktopOnly {
    display: none !important;
  }
}
