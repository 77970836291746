/* Link Page Element */
// All Styles - Specific .goToLink icon referenced in _link_icons

// 1. Page Element Styles
.linkElement{
  h4{
    font-family: $fontTwo;
    font-size: rem(12);
    font-weight: 400;
    a{
      background-color: $accent-color;
      display: block;
      color: #fff;
      // padding: 10px 20px 10px 10px;
      padding: 0;
      margin-bottom: 0px;
      line-height: 41px;
      text-decoration: none;
      transition: background-color .2s;
      max-height: 40px;
      min-height: 40px;
      padding-right: 15px;
      text-align: center;

      &:hover {
        background-color: $accent-color-hover;
      }

    }
  }
  .goToLink,
  .emailLink{
    &:before{
      display:none;
    }
    &:after{
      color: #ffffff;
      content: "\f101";
      font-family: FontAwesome;
      display: inline-block;
      line-height: 40px;
      margin: 0 0 0 6px;
      padding: 0;
      transition: color .2s;
      right: 0;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      background: $accent-color-hover;
      width: 40px;
    }
    &:hover:after {
      color: #fff;
    }
  }
  .emailLink {
    &:after{
      content: "\f003"; // envelope-o
    }
  }
  .text{
    font-size:rem(13);
    line-height: 1.5em;
    margin: auto;
    padding: 10px;
    background-color: #eee;
  }
}

// 2. Admin Panel Styles
.admin .linkElement{
  h4{
    font-family:$fontOne;
    font-size:rem(18);
    font-weight:700;
    background:transparent;
    padding:10px 0 5px 0;
    &:hover{
      background:transparent;
    }
    a{
      display: inline;
      color:$link-color;
      padding: 0;
      margin-bottom: 0px;
      background:transparent;
      text-decoration:none;
      opacity: 1;
      &:hover{
        text-decoration:underline;
      }
    }
  }
  .goToLink{
    &:before,
    &:after{
      display:none;
    }
  }
  .text{
    font-size:rem(13);
    padding: 0 0 10px 0;
    background-color: #fff;
  }
}
